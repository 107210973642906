import { default as _91_46_46_46uri_93FmSYeNXvC5Meta } from "/vercel/path0/pages/[...uri].vue?macro=true";
import { default as _91slug_93pKtR614pYhMeta } from "/vercel/path0/pages/[[lang]]/news/[slug].vue?macro=true";
import { default as indexsmbTqsSgx2Meta } from "/vercel/path0/pages/[[lang]]/search/index.vue?macro=true";
import { default as _91_46_46_46all_93H0MhJdxsYwMeta } from "/vercel/path0/pages/[[lang]]/shop/[...all].vue?macro=true";
import { default as address2YPObEUJW8Meta } from "/vercel/path0/pages/[[lang]]/shop/account/address.vue?macro=true";
import { default as indexhc1AOczA9IMeta } from "/vercel/path0/pages/[[lang]]/shop/account/index.vue?macro=true";
import { default as loginHCkRUadNKVMeta } from "/vercel/path0/pages/[[lang]]/shop/account/login.vue?macro=true";
import { default as _91id_93Hcqx09SUZEMeta } from "/vercel/path0/pages/[[lang]]/shop/account/order/[id].vue?macro=true";
import { default as indexFk1kIzmW0iMeta } from "/vercel/path0/pages/[[lang]]/shop/account/order/index.vue?macro=true";
import { default as paymentKv6cUNpZfBMeta } from "/vercel/path0/pages/[[lang]]/shop/account/payment.vue?macro=true";
import { default as profiletlLTQuDee2Meta } from "/vercel/path0/pages/[[lang]]/shop/account/profile.vue?macro=true";
import { default as index5nsMtRuQ1dMeta } from "/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue?macro=true";
import { default as passwordgg8RJUmFfdMeta } from "/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue?macro=true";
import { default as wishlist0wOQpJOxtAMeta } from "/vercel/path0/pages/[[lang]]/shop/account/wishlist.vue?macro=true";
import { default as failure3S0jwPb5p3Meta } from "/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue?macro=true";
import { default as successev0Fuos7ehMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue?macro=true";
import { default as cart7yUeZIbEicMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue?macro=true";
import { default as indexNTWEvTkKWSMeta } from "/vercel/path0/pages/[[lang]]/shop/checkout/index.vue?macro=true";
import { default as indexWmOjrMjbn0Meta } from "/vercel/path0/pages/[[lang]]/shop/index.vue?macro=true";
import { default as confirmEZ98ekM5DhMeta } from "/vercel/path0/pages/[[lang]]/shop/registration/confirm.vue?macro=true";
import { default as double_45opt_45inOe6FP08NT0Meta } from "/vercel/path0/pages/[[lang]]/shop/registration/double-opt-in.vue?macro=true";
import { default as indexhJ6Pbcku3IMeta } from "/vercel/path0/pages/[[lang]]/shop/registration/index.vue?macro=true";
export default [
  {
    name: "uri___de",
    path: "/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___fr",
    path: "/fr/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "uri___en",
    path: "/en/:uri(.*)*",
    component: () => import("/vercel/path0/pages/[...uri].vue").then(m => m.default || m)
  },
  {
    name: "lang-news-slug___de",
    path: "/:lang?/news/:slug()",
    component: () => import("/vercel/path0/pages/[[lang]]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-news-slug___fr",
    path: "/fr/:lang?/news/:slug()",
    component: () => import("/vercel/path0/pages/[[lang]]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lang-news-slug___en",
    path: "/en/:lang?/news/:slug()",
    component: () => import("/vercel/path0/pages/[[lang]]/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "Search___de",
    path: "/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "Search___fr",
    path: "/fr/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "Search___en",
    path: "/en/:lang?/search",
    component: () => import("/vercel/path0/pages/[[lang]]/search/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___de",
    path: "/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___fr",
    path: "/fr/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-all___en",
    path: "/en/:lang?/shop/:all(.*)*",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/[...all].vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___de",
    path: "/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___fr",
    path: "/fr/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-address___en",
    path: "/en/:lang?/shop/account/address",
    meta: address2YPObEUJW8Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/address.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account___de",
    path: "/:lang?/shop/account",
    meta: indexhc1AOczA9IMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account___fr",
    path: "/fr/:lang?/shop/account",
    meta: indexhc1AOczA9IMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account___en",
    path: "/en/:lang?/shop/account",
    meta: indexhc1AOczA9IMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-login___de",
    path: "/:lang?/shop/account/login",
    meta: loginHCkRUadNKVMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-login___fr",
    path: "/fr/:lang?/shop/account/login",
    meta: loginHCkRUadNKVMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/login.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-login___en",
    path: "/en/:lang?/shop/account/login",
    meta: loginHCkRUadNKVMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/login.vue").then(m => m.default || m)
  },
  {
    name: "OrderDetail___de",
    path: "/:lang?/shop/account/order/:id()",
    meta: _91id_93Hcqx09SUZEMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "OrderDetail___fr",
    path: "/fr/:lang?/shop/account/order/:id()",
    meta: _91id_93Hcqx09SUZEMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "OrderDetail___en",
    path: "/en/:lang?/shop/account/order/:id()",
    meta: _91id_93Hcqx09SUZEMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/[id].vue").then(m => m.default || m)
  },
  {
    name: "OrderHistory___de",
    path: "/:lang?/shop/account/order",
    meta: indexFk1kIzmW0iMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: "OrderHistory___fr",
    path: "/fr/:lang?/shop/account/order",
    meta: indexFk1kIzmW0iMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: "OrderHistory___en",
    path: "/en/:lang?/shop/account/order",
    meta: indexFk1kIzmW0iMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/order/index.vue").then(m => m.default || m)
  },
  {
    name: "AccountPayments___de",
    path: "/:lang?/shop/account/payment",
    meta: paymentKv6cUNpZfBMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: "AccountPayments___fr",
    path: "/fr/:lang?/shop/account/payment",
    meta: paymentKv6cUNpZfBMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: "AccountPayments___en",
    path: "/en/:lang?/shop/account/payment",
    meta: paymentKv6cUNpZfBMeta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/payment.vue").then(m => m.default || m)
  },
  {
    name: "ProfilePage___de",
    path: "/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "ProfilePage___fr",
    path: "/fr/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "ProfilePage___en",
    path: "/en/:lang?/shop/account/profile",
    meta: profiletlLTQuDee2Meta || {},
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___de",
    path: "/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___fr",
    path: "/fr/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover___en",
    path: "/en/:lang?/shop/account/recover",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___de",
    path: "/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___fr",
    path: "/fr/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-recover-password___en",
    path: "/en/:lang?/shop/account/recover/password",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/recover/password.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-wishlist___de",
    path: "/:lang?/shop/account/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-wishlist___fr",
    path: "/fr/:lang?/shop/account/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-account-wishlist___en",
    path: "/en/:lang?/shop/account/wishlist",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___de",
    path: "/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___fr",
    path: "/fr/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-failure___en",
    path: "/en/:lang?/shop/checkout/:id()/failure",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/failure.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___de",
    path: "/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___fr",
    path: "/fr/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-id-success___en",
    path: "/en/:lang?/shop/checkout/:id()/success",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___de",
    path: "/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___fr",
    path: "/fr/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout-cart___en",
    path: "/en/:lang?/shop/checkout/cart",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/cart.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___de",
    path: "/:lang?/shop/checkout",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___fr",
    path: "/fr/:lang?/shop/checkout",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-checkout___en",
    path: "/en/:lang?/shop/checkout",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop___de",
    path: "/:lang?/shop",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop___fr",
    path: "/fr/:lang?/shop",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop___en",
    path: "/en/:lang?/shop",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-confirm___de",
    path: "/:lang?/shop/registration/confirm",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/confirm.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-confirm___fr",
    path: "/fr/:lang?/shop/registration/confirm",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/confirm.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-confirm___en",
    path: "/en/:lang?/shop/registration/confirm",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/confirm.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-double-opt-in___de",
    path: "/:lang?/shop/registration/double-opt-in",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/double-opt-in.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-double-opt-in___fr",
    path: "/fr/:lang?/shop/registration/double-opt-in",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/double-opt-in.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration-double-opt-in___en",
    path: "/en/:lang?/shop/registration/double-opt-in",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/double-opt-in.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration___de",
    path: "/:lang?/shop/registration",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration___fr",
    path: "/fr/:lang?/shop/registration",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "lang-shop-registration___en",
    path: "/en/:lang?/shop/registration",
    component: () => import("/vercel/path0/pages/[[lang]]/shop/registration/index.vue").then(m => m.default || m)
  }
]